/*************************************************************************
*
* © 2020 Whisper LLC
*
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Whisper LLC and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Whisper LLC
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Whisper LLC. Reverse engineering of any portion of this code 
* is in violation of the Terms of Use policy.
*************************************************************************/

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import rootReducer from '../src/redux/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"; // <-- New code

import thunk from "redux-thunk";
import ScrollToTop from "./utils/scrollToTop";
import PreLoader from './Components/Sections/CommonSection/PreLoader';

let store = createStore(rootReducer, applyMiddleware(thunk))

const AppComponent = React.lazy(() => import('./App'));
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Suspense fallback={<PreLoader />}>
            <AppComponent />
          </Suspense>
          {/* <AddResturant /> */}
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
