/*************************************************************************
*
* © 2020 Whisper LLC
*
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Whisper LLC and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Whisper LLC
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Whisper LLC. Reverse engineering of any portion of this code 
* is in violation of the Terms of Use policy.
*************************************************************************/

const isDev = () => {
  if (process.env.NODE_ENV == 'production')
    return false;
  else
    return true;
}

export const __DEV = isDev();
