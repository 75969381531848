import { __DEV } from "../../isDev";

export function takeOutMenuDetails(state = {}, action) {
  switch (action.type) {
    case "TAKEOUT_MENU_DETAILS":
      return action.data;
    default:
      return state;
  }
}
