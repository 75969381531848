import { combineReducers } from "redux";
import { userDetails } from "./reducers/userDetails";
import { allRestaurants } from "./reducers/allRestaurants";
import { openedRestaurants } from "./reducers/openedRestaurants";
import { closedRestaurants } from "./reducers/closedRestaurants";
import { cuisineList } from "./reducers/cuisineList";
import { menuListByRestaurant } from "./reducers/menuListByRestaurant";
import { selectedRestaurant } from "./reducers/selectedRestaurant";
import { allStaff } from "./reducers/allStaff";
import { activeStaff } from "./reducers/activeStaff";
import { inactiveStaff } from "./reducers/inactiveStaff";
import { resturantDetails } from './reducers/resturantDetails';
import { addToCart } from './reducers/addToCart';
import { customerDetails } from './reducers/customerDetails';
import { customerByRestaurant } from './reducers/customerByRestaurant';
import { allOrderList } from './reducers/allOrderList';
import { activeMenu } from './reducers/activeMenu';
import { activeMenuCategoryList } from './reducers/activeMenuCategoryList';
import { paymentList } from './reducers/paymentList';
import { paidOrders } from './reducers/paidOrders';
import { unpaidOrders } from './reducers/unpaidOrders';
import { socketDetails } from './reducers/socketDetails';
import { ordernotification } from './reducers/ordernotification';
import { salesReportList } from './reducers/salesReportList';
import { takeOutMenuDetails } from './reducers/takeOutMenuDetails';



const rootReducer = combineReducers({
    userDetails,
    unpaidOrders,
    paidOrders,
    allRestaurants,
    openedRestaurants,
    closedRestaurants,
    cuisineList,
    menuListByRestaurant,
    selectedRestaurant,
    allStaff,
    activeStaff,
    inactiveStaff,
    resturantDetails,
    addToCart,
    customerDetails,
    customerByRestaurant,
    allOrderList,
    activeMenu,
    activeMenuCategoryList,
    paymentList,
    socketDetails,
    ordernotification,
    salesReportList,
    takeOutMenuDetails

});

export default rootReducer;
