import { __DEV } from "../../isDev";

export function resturantDetails(state = {}, action) {
  switch (action.type) {
    case "RESTURANT_DETAILS":
      return action.data;
    default:
      return state;
  }
}
